<template>
  <section class="section">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Section',
}
</script>

<style lang="scss" scoped>
/* SECTION */

.section {
  @include v-padding($gutter);
}

.section--no-padding {
  @include v-padding(0);
}

.section--list {
  padding-top: 0;
}

.section--border-top {
  border-top: 1px solid $color-gray-lighter;
}

.section--border-bottom {
  border-bottom: 1px solid $color-gray-lighter;
}
</style>
